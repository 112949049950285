.information {
  display: flex;
  justify-content: center;
  padding: 0.3rem 1.4rem 0 0;
}

.information :deep(.ant-input) {
  width: 5rem;
  height: 0.5rem;
  line-height: 0.5rem;
  padding: 0 0.2rem;
  font-size: 0.16rem;
  color: #555555;
}

.information :deep(.ant-input-disabled) {
  background-color: #eeeeee;
  border-color: #eeeeee !important;
}

.form-item {
  display: flex;
  align-items: center;
  position: relative;
}

.form-item .label {
  width: 65PX;
  text-align: justify;
  text-align-last: justify;
  margin-right: 0.45rem;
  font-size: 0.16rem;
  color: #333333;
  font-weight: bold;
}

.form-item .input {
  position: relative;
}

.icon_user {
  font-size: 1rem !important;
}

:deep(.ant-avatar) {
  background-color: #fff;
  position: relative;
}

:deep(.ant-avatar) .ant-avatar-string {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform: scale(1) translateX(0) !important;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
  background-color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  color: #fff;
}

.anticon-loading {
  line-height: 1;
}

.ant-upload-text {
  line-height: 1;
  font-size: 0.14rem;
  margin-top: 0.05rem;
}

.handle {
  position: absolute;
  left: calc(100% + 0.3rem);
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  font-size: 0.16rem;
  color: #888888;
}

.handle.edit span {
  text-decoration: underline;
  cursor: pointer;
}

:deep(.avatar-uploader) .ant-upload {
  width: auto;
  height: auto;
  white-space: nowrap;
  margin: 0 !important;
  line-height: 1;
  border: none;
  background-color: transparent;
  font-size: 0.16rem;
  padding: 0 !important;
  color: #888888;
  text-decoration: underline;
}

.btn {
  flex: 1;
  text-align: center;
  margin-top: 0.5rem;
}

.btn .ant-btn {
  min-width: 2rem;
  padding: 0 0.2rem;
  height: 0.5rem;
  line-height: 0.5rem;
  border-radius: 0.25rem;
  background-color: #00ffc0;
  border-color: #00ffc0;
  font-size: 0.18rem;
  color: #000000;
}

.btn .ant-btn:focus,
.btn .ant-btn:hover {
  background-color: #79f9d9;
  border-color: #79f9d9;
  color: #000;
}

.btn .ant-btn:active {
  background-color: #00ff7e;
  border-color: #00ff7e;
  color: #000;
}

:deep(.ant-form-explain) {
  padding-left: calc(65Px + 0.45rem);
}

.wechat {
  width: 300Px;
}

.dialog_content {
  display: flex;
  justify-content: center;
}

.logoff {
  margin-right: 0.5rem;
  text-decoration: underline;
}

.logoff:hover {
  color: #00ffc0;
}